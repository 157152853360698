import http from "../http-common";

class ManageService {
  /************* start  */

  async getStudentList(data) {
    return http.post(`/api/studentlist.php`, data);
  }
  getList(data) {
    return http.post(`/api/listall.php`, data);
  }
  async getAllList(data) {
    return http.post(`/api/listall.php`, data);
  }
  getAttendanceList(data) {
    return http.post(`/api/memberlist.php`, data);
  }
  async getGradeListAll(data) {
    return http.post(`/api/grade_list.php`, data);
  }
  async getDaysList() {
    //날짜 가져오기
    return http.post(`/api/attendlist.php`);
  }
  async setAttendChech(data) {
    //출결 체크
    return http.post(`/api/attendsetting.php`, data);
  }
  reportWrite(data) {
    //보고서 작성
    return http.post(`/api/reportWrite.php`, data);
  }
  getteacher(data) {
    //선생님 리스트
    return http.post(`/api/teacherlist.php`, data);
  }
  register(data) {
    // 학생 등록
    return http.post(`/api/register.php`, data);
  }
  memberDelete(form) {
    //멤버 탈퇴
    return http.post(`/api/member_delete.php`, form);
  }
  getenrolled(data) {
    //반 제적
    return http.post(`/api/enrolled.php`, data);
  }

  getenrolllist(data) {
    //반 출결
    return http.post(`/api/enrolledlist.php`, data);
  }
  gettotalattend(data) {
    //전체 출결
    return http.post(`/api/attendalllist.php`, data);
  }

  photoUpload(form) {
    //사진업로드
    return http.post(`/api/uploadphoto.php`, form);
  }

  getboardDashboard(data) {
    //대시보드 공지사항
    return http.post(`/api/board_notice.php`, data);
  }

  getboardboardall(form) {
    //공지사항
    return http.post(`/api/board_all.php`, form);
  }

  writerBoardData(form) {
    //공지사항 등록
    return http.post(`/api/board_writer.php`, form);
  }

  deleteBoardData(form) {
    //공지사항 삭제
    return http.post(`/api/board_delete.php`, form);
  }

  getclassreport(form) {
    //분반보고서현황
    return http.post(`/api/class_reports.php`, form);
  }

  getweeklyreport(form) {
    //양육보고서현황
    return http.post(`/api/weekly_reports.php`, form);
  }

  weeklyreportWrite(data) {
    //양육보고서 피드백 등록
    return http.post(`/api/weeklyreportWrite.php`, data);
  }

  setGoOut(data) {
    //회원탈퇴
    return http.post(`/api/memberGoOut.php`, data);
  }

  getAbsentAll(data) {
    //장결자 리스트
    return http.post(`/api/getAbsentList.php`, data);
  }

  setReadCheck(data) {
    //읽음 표시
    return http.post(`/api/setReadCheck.php`, data);
  }
  personreports(data) {
    //학생별보고서 - 학생 자료 가져오기
    return http.post(`/api/personreports.php`, data);
  }
  searchstudent(data) {
    //학생별보고서 - 학생 리스트 찾기
    return http.post(`/api/searchstudent.php`, data);
  }
  savephoto(data) {
    //학생별보고서 - 학생 리스트 찾기
    return http.post(`/api/savephoto.php`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  savephotoimage(data) {
    //사진 저장하기
    return http.post(`/api/savephotoimage.php`, data);
  }

  setPrivateInfoAgree(data) {
    //동의처리하기
    return http.post(`/api/setprivateinfoagree.php`, data);
  }

  /** 주보등록 */

  deleteNewsBoardData(form) {
    //주보 삭제
    return http.post(`/api/newsboard_delete.php`, form);
  }

  writerNewsBoardData(form) {
    //주보 등록
    return http.post(`/api/newsboard_writer.php`, form);
  }

  getNewsboardall(form) {
    //주보 리스트
    return http.post(`/api/newsboard_all.php`, form);
  }

  setReadPayCheck(data) {
    //읽음 표시
    return http.post(`/api/setReadPayCheck.php`, data);
  }
  /************* end  */
  insert(data) {
    return http.post(`/manage/insert/`, data);
  }
  getDeptList(data) {
    return http.post(`/manage/dept/list/`, data);
  }
  getExcelDownload(data) {
    return http.post(`/manage/excel/list/`, data);
  }
  getPageList(params) {
    return http.get(`/manage/listpage/`, params);
  }

  sendToPLMsg(data) {
    return http.post(`/manage/sendpl/`, data);
  }

  getExcelDownload_dept(data) {
    return http.post(`/manage/excel/list_dept/`, data);
  }
}

export default new ManageService();
