<template>
  <div id="excel">
    <div style="text-align: right; margin-bottom: 10px">
      <vue-excel-xlsx
        :data="lists"
        :columns="columns"
        :file-name="filename"
        :file-type="'xlsx'"
        :sheet-name="'전체목록'"
        class="btn btn-primary btn-sm"
      >
        Excel Download
      </vue-excel-xlsx>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">등록일</th>
            <th scope="col">이름</th>
            <th scope="col">부서</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="list in lists" :key="list.id" :id="list.id">
            <th scope="row">{{ dateFormat(list.regdate) }}</th>
            <td>{{ list.membername }}</td>
            <td>{{ list.dept }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
    </div>
  </div>
</template>

<script>
import manageService from "../services/manageService";
import moment from "moment";

export default {
  name: "excel",
  component: {},
  data() {
    return {
      columns: [
        {
          label: "행번",
          field: "memberno",
        },
        {
          label: "성명",
          field: "membername",
        },
        {
          label: "팀",
          field: "dept",
        },
        {
          label: "연락처",
          field: "tel",
        },
        {
          label: "백신 접종",
          field: "vaccine",
        },
        {
          label: "단계",
          field: "sector",
        },
        {
          label: "발생 경로",
          field: "path",
        },
        {
          label: "주변근무자",
          field: "spreader",
        },
        {
          label: "PL 의견",
          field: "pl",
        },
        {
          label: "PMO 의견",
          field: "pmo",
        },
        {
          label: "등록일",
          field: "regdate",
        },
      ],
      data: this.list,
      lists: [],
      filename: "KDBITO_"+this.getDateFormat(),
    };
  },
  computed: {},
  created() {
    this.getManageExcelList();
  },
  methods: {
    getDateFormat() {
  
      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date();

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }
      var ddate = year + "" + month + "" + day;

      return moment(String(ddate)).format("YYYYMMDD");
    },
    dateFormat(dd){
        return moment(String(dd)).format("YYYY-MM-DD");
    },
    priceFormat(value) {
      return "$ " + value;
    },
    getManageExcelList() {
      const data = {
        aa: "11",
      };
      manageService
        .getExcelDownload(data)
        .then((response) => {
          this.lists = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style></style>
